
// Interface Setting Action Types

// Set Interface Setting for Sale Orders
export const SET_SALE_ORDER_PAGE_SEARCH_CRITERIA = 'SET_SALE_ORDER_PAGE_SEARCH_CRITERIA';
export const SET_SALE_ORDER_PAGE_SEARCH_FILTER = 'SET_SALE_ORDER_PAGE_SEARCH_FILTER';
export const SET_SALE_ORDER_PAGE_PAGINATE = 'SET_SALE_ORDER_PAGE_PAGINATE';
export const SET_SALE_ORDERS_FILTER = 'SET_SALE_ORDERS_FILTER';
export const SET_SALE_ORDERS_PAGINATE = 'SET_SALE_ORDERS_PAGINATE';
export const SET_SALE_ORDERS_SORT = 'SET_SALE_ORDERS_SORT';
export const SET_SALE_ORDERS_SEARCH = 'SET_SALE_ORDERS_SEARCH';
export const SET_SALE_ORDERS_URL = 'SET_SALE_ORDERS_URL';

export const SET_PAYLOAD_PAGE_SEARCH_CRITERIA = 'SET_PAYLOAD_PAGE_SEARCH_CRITERIA';
export const SET_PAYLOAD_PAGE_SEARCH_FILTER = 'SET_PAYLOAD_PAGE_SEARCH_FILTER';
export const SET_PAYLOAD_PAGE_PAGINATE = 'SET_PAYLOAD_PAGE_PAGINATE';

export const SET_TRAVELER_PAGE_SEARCH_CRITERIA = 'SET_TRAVELER_PAGE_SEARCH_CRITERIA'
export const SET_TRAVELER_PAGE_SEARCH_FILTER = 'SET_TRAVELER_PAGE_SEARCH_FILTER'
export const SET_TRAVELER_PAGE_PAGINATE = 'SET_TRAVELER_PAGE_PAGINATE'


export const SET_STOCK_PAGE_SEARCH_CRITERIA = 'SET_STOCK_PAGE_SEARCH_CRITERIA'
export const SET_STOCK_PAGE_SEARCH_FILTER = 'SET_STOCK_PAGE_SEARCH_FILTER'
export const SET_STOCK_PAGE_PAGINATE = 'SET_STOCK_PAGE_PAGINATE'


export const SET_DEPARTMENT_ACTIVITY_PAGE_SEARCH_CRITERIA = 'SET_DEPARTMENT_ACTIVITY_PAGE_SEARCH_CRITERIA'
export const SET_DEPARTMENT_ACTIVITY_PAGE_SEARCH_FILTER = 'SET_DEPARTMENT_ACTIVITY_PAGE_SEARCH_FILTER'
export const SET_DEPARTMENT_ACTIVITY_PAGE_PAGINATE = 'SET_DEPARTMENT_ACTIVITY_PAGE_PAGINATE'


export const SET_SHIPPING_PAGE_SEARCH_CRITERIA = 'SET_SHIPPING_PAGE_SEARCH_CRITERIA';
export const SET_SHIPPING_PAGE_SEARCH_FILTER = 'SET_SHIPPING_PAGE_SEARCH_FILTER';
export const SET_SHIPPING_PAGE_PAGINATE = 'SET_SHIPPING_PAGE_PAGINATE';


export const SET_PACKING_PAGE_SEARCH_CRITERIA = 'SET_PACKING_PAGE_SEARCH_CRITERIA';
export const SET_PACKING_PAGE_SEARCH_FILTER = 'SET_PACKING_PAGE_SEARCH_FILTER';
export const SET_PACKING_PAGE_PAGINATE = 'SET_PACKING_PAGE_PAGINATE';
