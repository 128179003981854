import { combineReducers } from "redux";

import userReducer from "./userReducer";
import companyReducer from "./companyReducer";
import partReducer from "./partReducer";
import departmentReducer from "./departmentReducer";
import managerReducer from "./managerReducer";
import saleOrderReducer from "./saleOrderReducer";
import stockItemReducer from "./StockReducer";
import payloadItemReducer from "./payloadItemReducer";
import travelerReducer from "./travelerReducer";
import viewModeReducer from "./viewModeReducer";
import interfaceSettingsReducer from "./interfaceSettingReducer";
import ncrReducer from "./ncrReducer";
import shippingReducer from "./shippingReducer";
import packingReducer from "./packingReducer";


const rootReducer = combineReducers({
  userrr: userReducer,
  companyyy: companyReducer,
  parttt: partReducer,
  departmenttt: departmentReducer,
  managersss: managerReducer,
  saleorderrr: saleOrderReducer,
  stock:stockItemReducer,
  payloadItemmm: payloadItemReducer,
  travelerrr: travelerReducer,
  viewModeee: viewModeReducer,
  interfaceSettings: interfaceSettingsReducer,
  ncrrr: ncrReducer,
  shipinggg: shippingReducer,
  packinggg: packingReducer

});

export default rootReducer;
