import {

  FETCH_PACKINGS_SUCCESS,
  FETCH_PACKINGS_STARTED,
  FETCH_PACKINGS_FAILURE,

  FETCH_SHIPPING_STARTED,
  FETCH_SHIPPING_SUCCESS,
  FETCH_SHIPPING_FAILURE,
  
  RESET_SHIPPING_SELECTED


} from "../actions/types";

const initialState = {
  payloadItems: [],
  pendingByTravelers: [],
  paginate: {},
  selected: null,
  loading: false,
  error: null
};

const packingReducer = (state = initialState, action) => {
  switch (action.type) {
    //FETCH

    case FETCH_PACKINGS_STARTED:
      return {
        ...state,
        paginate: {},
        error: null,
        loading: true
      };
    case FETCH_PACKINGS_SUCCESS:
      return {
        ...state,
        packings: action.payload.packings,
        paginate: action.payload.paginate,
        loading: false,
        error: null
      };

    case FETCH_PACKINGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };


    //FETCH ITEM

    case FETCH_SHIPPING_STARTED:
      return {
        ...state,
        loading: true,
        selected: null,
      };
    case FETCH_SHIPPING_SUCCESS:

      return {
        ...state,
        selected: action.payload.packing,
        loading: false,
        error: null
      };

    case FETCH_SHIPPING_FAILURE:
      return {
        ...state,
        loading: false,
        selected: null,
        error: action.payload.error
      };

    //RESTE
    case RESET_SHIPPING_SELECTED: {
      return {
        ...state,
        loading: false,
        selected: null,
      };
    }

  
    default:
      return state;
  }
};

export default packingReducer;
