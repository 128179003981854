import {

  FETCH_SHIPPINGS_SUCCESS,
  FETCH_SHIPPINGS_STARTED,
  FETCH_SHIPPINGS_FAILURE,

  FETCH_SHIPPING_STARTED,
  FETCH_SHIPPING_SUCCESS,
  FETCH_SHIPPING_FAILURE,
  
  RESET_SHIPPING_SELECTED


} from "../actions/types";

const initialState = {
  payloadItems: [],
  pendingByTravelers: [],
  paginate: {},
  selected: null,
  loading: false,
  error: null
};

const shippingReducer = (state = initialState, action) => {
  switch (action.type) {
    //FETCH

    case FETCH_SHIPPINGS_STARTED:
      return {
        ...state,
        paginate: {},
        error: null,
        loading: true
      };
    case FETCH_SHIPPINGS_SUCCESS:
      return {
        ...state,
        shippings: action.payload.shippings,
        paginate: action.payload.paginate,
        loading: false,
        error: null
      };

    case FETCH_SHIPPINGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };


    //FETCH ITEM

    case FETCH_SHIPPING_STARTED:
      return {
        ...state,
        loading: true,
        selected: null,
      };
    case FETCH_SHIPPING_SUCCESS:

      return {
        ...state,
        selected: action.payload.shipping,
        loading: false,
        error: null
      };

    case FETCH_SHIPPING_FAILURE:
      return {
        ...state,
        loading: false,
        selected: null,
        error: action.payload.error
      };

    //RESTE
    case RESET_SHIPPING_SELECTED: {
      return {
        ...state,
        loading: false,
        selected: null,
      };
    }

  
    default:
      return state;
  }
};

export default shippingReducer;
