
import { SET_DEPARTMENT_ACTIVITY_PAGE_PAGINATE, SET_DEPARTMENT_ACTIVITY_PAGE_SEARCH_CRITERIA, SET_DEPARTMENT_ACTIVITY_PAGE_SEARCH_FILTER, SET_PAYLOAD_PAGE_PAGINATE, SET_PAYLOAD_PAGE_SEARCH_CRITERIA, SET_PAYLOAD_PAGE_SEARCH_FILTER, SET_SALE_ORDER_PAGE_PAGINATE, SET_SALE_ORDER_PAGE_SEARCH_CRITERIA, SET_SALE_ORDER_PAGE_SEARCH_FILTER, SET_SHIPPING_PAGE_PAGINATE, SET_SHIPPING_PAGE_SEARCH_CRITERIA, SET_SHIPPING_PAGE_SEARCH_FILTER, SET_STOCK_PAGE_PAGINATE, SET_STOCK_PAGE_SEARCH_CRITERIA, SET_STOCK_PAGE_SEARCH_FILTER, SET_TRAVELER_PAGE_PAGINATE, SET_TRAVELER_PAGE_SEARCH_CRITERIA, SET_TRAVELER_PAGE_SEARCH_FILTER } from "@redux/actions/interface-setting-actions/types";

const initialState = {
    saleOrders: {
        status: {
            stage: ['completed', 'canceled', 'launched', 'hold', 'processing'],
            priority: [1, 2, 3, 4, 5],
        },
        filter: {},
        paginate: {
            page: 1,
            limit: 25,
            total: 0,
            pages: 0,
        },
        sort: [
            { path: 'status.stage', sort: -1, },
            { path: 'due', type: 'date', sort: 1 },
            { path: 'po', sort: 1, filter: 1 },
            { path: 'to.id', filter: 1 },
            { path: 'payload.part.id', filter: 1 },
            { path: 'parts.id', filter: 1 },
            { path: 'payload.part.description', filter: 1 },
        ],

        /* criteria: {
             $or: [
                 { 'status.stage': { $eq: 'completed' } },
                 { 'status.stage': { $eq: 'launched' } },
                 { 'status.stage': { $eq: 'hold' } },
                 { 'status.stage': { $eq: 'returned' } },
                 { 'status.stage': { $eq: 'processing' } }
             ]
         }*/

    },
    stockItems: {

        filter: {},
        paginate: {
            page: 1,
            limit: 25,
            total: 0,
            pages: 0,
        },
        sort: [
            { path: 'id', filter: 1, sort: 1 },
            { path: 'part.id', sort: 1, filter: 1 },
            { path: 'createdAt', type: 'date', sort: -1 },
            { path: 'id', filter: 1 },
            { path: 'part.description', filter: 1 },
            { path: 'part.type', filter: 1 },
            { path: 'department.id', filter: 1 },

        ],


    },

    payloads: {
        status: {
            stage: ['completed','shipped', 'canceled', 'launched', 'hold', 'processing'],
            priority: [1, 2, 3, 4, 5],
        },
        filter: {},
        paginate: {
            page: 1,
            limit: 25,
            total: 0,
            pages: 0,
        },
        sort: [
            { path: 'status.priority', sort: 1, },
            { path: 'due', type: 'date', filter: 1, sort: 1 },
            { path: 'company.id', filter: 1 },
            { path: 'part.id', filter: 1, sort: 1 },
            { path: 'part.description', filter: 1 },
            { path: 'po', filter: 1 },
            { path: 'part.type', filter: 1 }
        ],


    },

    travelers: {
        status: {
            stage: ['completed', 'launched', 'hold', 'processing','shipping','shipped','delivered'],
            priority: [1, 2, 3, 4, 5],
        },
        filter: {},
        paginate: {
            page: 1,
            limit: 50,
            total: 0,
            pages: 0,
        },
        sort: [
            { path: 'status.priority', sort: 1, },
            { path: 'payload_due', sort: 1 },
            { path: 'id', filter: 1, sort: 1 },
            { path: 'payload_company.id', filter: 1 },
            { path: 'payload_part.id', filter: 1 },
            { path: 'payload_part.type', filter: 1 },
            { path: 'po', filter: 1 },
        ],

        /* criteria: {
             $or: [
                 { 'status.stage': { $eq: 'launched' } },
                 { 'status.stage': { $eq: 'hold' } },
                 { 'status.stage': { $eq: 'returned' } },
                 { 'status.stage': { $eq: 'processing' } }
             ]
         }*/

    },
    department_activity: {
        filter: {},
        paginate: {
            page: 1,
            limit: 25,
            total: 0,
            pages: 0,
        },
        sort: [
            { path: 'cretaedAt', type: 'date', sort: -1 },
            { path: 'updatedAt', type: 'date', sort: -1 },
        ]


    },
    shippings: {
        status: { stage: [ 'shipping','shipped','delivered','completed'] },
        filter: {},
        paginate: {
            page: 1,
            limit: 25,
            total: 0,
            pages: 0,
        },
        sort: [
            { path: 'id', filter: 1, sort: 1 },
            { path: 'department.id', filter: 1 },
            { path: 'summary.po', filter: 1 },
            { path: 'summary.parts.id', filter: 1 },
            { path: 'packaging.id', filter: 1 },
            { path: 'createdAt', type: 'date', sort: -1 },
            { path: 'updatedAt', type: 'date', sort: -1 },


        ]
    },
    packings: {
        status: { stage: ["in_progress", "pending"] },
        filter: {},
        paginate: {
            page: 1,
            limit: 25,
            total: 0,
            pages: 0,
        },
        sort: [
            { path: 'id', filter: 1, sort: 1 },
            { path: 'department.id', filter: 1 },
            { path: 'summary.po', filter: 1 },
            { path: 'summary.parts.id', filter: 1 },
            { path: 'packaging.id', filter: 1 },
            { path: 'createdAt', type: 'date', sort: -1 },
            { path: 'updatedAt', type: 'date', sort: -1 },
        ]

    },






};



const interfaceSettingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SALE_ORDER_PAGE_SEARCH_CRITERIA:

            return {
                ...state,
                saleOrders: {
                    ...state.saleOrders,
                    criteria: action.payload.criteria  // This is the criteria that is being set  
                }
            };

        case SET_SALE_ORDER_PAGE_SEARCH_FILTER:
            return {
                ...state,
                saleOrders: {
                    ...state.saleOrders,
                    filter: action.payload.filter  // This is the filter that is being set  
                }
            };

        case SET_SALE_ORDER_PAGE_PAGINATE:
            return {
                ...state,
                saleOrders: {
                    ...state.saleOrders,
                    paginate: action.payload.paginate  // This is the paginate that is being set  
                }
            };

        case SET_PAYLOAD_PAGE_SEARCH_CRITERIA:
            return {
                ...state,
                payloads: {
                    ...state.payloads,
                    criteria: action.payload.criteria  // This is the criteria that is being set  
                }
            };
        case SET_PAYLOAD_PAGE_SEARCH_FILTER:
            return {
                ...state,
                payloads: {
                    ...state.payloads,
                    filter: action.payload.filter  // This is the filter that is being set  
                }
            };
        case SET_PAYLOAD_PAGE_PAGINATE:
            return {
                ...state,
                payloads: {
                    ...state.payloads,
                    paginate: action.payload.paginate  // This is the paginate that is being set  
                }
            };

        case SET_TRAVELER_PAGE_SEARCH_CRITERIA:
            return {
                ...state,
                travelers: {
                    ...state.travelers,
                    criteria: action.payload.criteria  // This is the criteria that is being set  
                }
            };
        case SET_TRAVELER_PAGE_SEARCH_FILTER:
            return {
                ...state,
                travelers: {
                    ...state.travelers,
                    filter: action.payload.filter  // This is the filter that is being set  
                }
            };
        case SET_TRAVELER_PAGE_PAGINATE:
            return {
                ...state,
                travelers: {
                    ...state.travelers,
                    paginate: action.payload.paginate  // This is the paginate that is being set  
                }
            };


        // Stock Items
        case SET_STOCK_PAGE_SEARCH_CRITERIA:
            return {
                ...state,
                stockItems: {
                    ...state.stockItems,
                    criteria: action.payload.criteria  // This is the criteria that is being set  
                }
            };
        case SET_STOCK_PAGE_SEARCH_FILTER:
            return {
                ...state,
                stockItems: {
                    ...state.stockItems,
                    filter: action.payload.filter  // This is the filter that is being set  
                }
            };

        case SET_STOCK_PAGE_PAGINATE:
            return {
                ...state,
                stockItems: {
                    ...state.stockItems,
                    paginate: action.payload.paginate  // This is the paginate that is being set  
                }
            };




        // Department Activity
        case SET_DEPARTMENT_ACTIVITY_PAGE_SEARCH_CRITERIA:
            return {
                ...state,
                department_activity: {
                    ...state.department_activity,
                    criteria: action.payload.criteria  // This is the criteria that is being set  
                }
            };
        case SET_DEPARTMENT_ACTIVITY_PAGE_SEARCH_FILTER:
            return {
                ...state,
                department_activity: {
                    ...state.department_activity,
                    filter: action.payload.filter  // This is the filter that is being set  
                }
            };

        case SET_DEPARTMENT_ACTIVITY_PAGE_PAGINATE:
            return {
                ...state,
                department_activity: {
                    ...state.department_activity,
                    paginate: action.payload.paginate  // This is the paginate that is being set  
                }
            };


        // Shippings

        case SET_SHIPPING_PAGE_SEARCH_CRITERIA:
            return {
                ...state,
                shippings: {
                    ...state.shippings,
                    criteria: action.payload.criteria  // This is the criteria that is being set  
                }
            };
        case SET_SHIPPING_PAGE_SEARCH_FILTER:
            return {
                ...state,
                shippings: {
                    ...state.shippings,
                    filter: action.payload.filter  // This is the filter that is being set  
                }
            };

        case SET_SHIPPING_PAGE_PAGINATE:
            return {
                ...state,
                shippings: {
                    ...state.shippings,
                    paginate: action.payload.paginate  // This is the paginate that is being set  
                }
            };



        default:
            return state;
    }
};



export default interfaceSettingsReducer;
